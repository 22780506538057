import { useStaticQuery, graphql } from "gatsby"

 export const useModelData = () => {

  const data = useStaticQuery(
    graphql`
    query {
      card: file(relativePath: { eq: "octoCard.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      model: allFile(filter:{relativePath:{regex:"/(.*tree.*glb)/"}}) {
        edges {
          node {
            id
            publicURL
          }
        }
      }
    }
    `
  )
  return data
}