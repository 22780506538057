import * as THREE from 'three/src/Three'
import React, {Suspense, useState, useRef, useMemo } from 'react'
// A THREE.js React renderer, see: https://github.com/drcmda/react-three-fiber
import { Canvas, useFrame, useThree } from 'react-three-fiber'

// A React animation lib, see: https://github.com/react-spring/react-spring

import SimplexNoise from 'simplex-noise'
import Model from '../components/Model'
import Gradient from "../components/Gradient"

let conf = {
  fov: 75,
  cameraZ: 100,
  xyCoef: 7,
  zCoef: 2,
  lightIntensity: 1 ,
  ambientColor: 0x000000,
  light1Color: 0x0E09DC,
  light2Color: 0x1CD1E1,
  light3Color: 0x18C02C,
  light4Color: 0xee3bcf,
}

const lightDistance = 500
const simplex = new SimplexNoise();

function Shapes() {
  let group = useRef()

  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  useFrame(() => {
    group.current.children.forEach(mesh => {
      mesh.rotation.x += .02
      mesh.rotation.y += .02
      mesh.rotation.z += .02
    })
  })
  
  const [shapes, mat] = useMemo(() => {
    const shapes = [
      { geo: new THREE.TorusBufferGeometry(10, 3, 20, 20), coords: [-50,50,0]},
      { geo: new THREE.IcosahedronBufferGeometry(10,0), coords: [50,20,0]},
      { geo: new THREE.CylinderBufferGeometry( 5, 5, 20, 32 ), coords: [-50,-40,0]},
      { geo: new THREE.BoxBufferGeometry(10,10,10), coords: [50,-60,0]}
    ]
    let mat = new THREE.MeshLambertMaterial({ color: new THREE.Color('white'), side: THREE.DoubleSide, wireframe: false })
    return [shapes, mat]
  }, [])

  return (
    <group ref={group}>
      {shapes.map((shape, i) => (
        <mesh       
          scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
          onClick={e => setActive(!active)}
          key={i} geometry={shape.geo} material={mat} position={shape.coords} />
      ))}
    </group>
  )
}

function Wave({scale= 20, rotation=[Math.PI/2.5,0,Math.PI/16], width=150, height=60, wSegments=150, hSegments=60, octaves=4, persistence=.5, lacunarity=2 }) {
  let group = useRef()
  const { size, viewport } = useThree()
  const mobile = size.width < 600

  let maxNoiseHeight = Number.MAX_VALUE
  let minNoiseHeight = Number.MIN_VALUE

  useFrame(() => {
    const time = Date.now() * 0.00008
    group.current.children.forEach(planeMesh => {
      let position = planeMesh.geometry.attributes.position.array

      for (let i = 0; i < position.length; i += 3) {

        let frequency = 1
        let amplitude = 4
        let noiseHeight = 0
        let x = position[i]
        let y = position[i + 1]

        for(let i=0; i < octaves; i++){
          let sampleX = x / scale * frequency
          let sampleY = y / scale * frequency
          let noiseValue = simplex.noise3D(sampleX, sampleY, time)
          noiseHeight += noiseValue*amplitude
          amplitude *= persistence
          frequency *= lacunarity
        }

        if(noiseHeight > maxNoiseHeight) {
          maxNoiseHeight=noiseHeight
        }
        else if(noiseHeight < minNoiseHeight){
          minNoiseHeight=noiseHeight
        }
        position[i + 2] = noiseHeight
      }
      planeMesh.geometry.attributes.position.needsUpdate = true
    })
  })

  const [geo, mat, vertices, coords] = useMemo(() => {
    let mat = new THREE.MeshLambertMaterial({ color: new THREE.Color('gray'), side: THREE.DoubleSide, wireframe: true, polygonOffset: true,
        polygonOffsetFactor: 1, // positive value pushes polygon further away
        polygonOffsetUnits: 1 })
    let geo = new THREE.PlaneBufferGeometry(width, height, wSegments, hSegments)
    const coords = new Array(1).fill().map(i => [0, mobile? 170 :115, 0])
    return [geo, mat, vertices, coords]
  }, [width, height, wSegments, hSegments, mobile])

  return (
    <group ref={group}>
      {coords.map(([p1, p2, p3], i) => (
        <mesh key={i} geometry={geo} material={mat} position={[p1, p2, p3]} rotation={rotation}/>      
      ))}
    </group>
  )
}

const Background = (props) => {
  const r = 10;
  const y = 50;
  const {data} = props

  return(
    <Canvas concurrent="true" pixelRatio={1} orthographic  camera={{ zoom: 10, position: [0, 0, 500] }}>
      <Suspense fallback={null}>
      {/* <ambientLight color={conf.ambientColor} intensity={.8}/> */}
      <pointLight color={conf.light1Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[-20, 100, 10]}/>
      <pointLight color={conf.light2Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[20, 100, 10]}/>
      <pointLight color={conf.light3Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[-20, 100, -10]}/>
      <pointLight color={conf.light4Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[20, 100, -10]}/>

      <Wave scale={25} rotation={[Math.PI/2.5,0,0]} width={200} height={120} wSegments={100} hSegments={60} octaves={4} persistence={.5} lacunarity={2}/>
      <Shapes />

      {/* <pointLight color={new THREE.Color('yellow')} intensity={1} distance={100} position={[50,75,0]}/> */}
      <Model url={data.model.edges[0].node.publicURL} />
      
      {/* <Block factor={1} offset={0}>
        <Gradient args={[193, 101.5, 32, 32]} shift={-4} rotation={[0, 0,0]} position={[0, 100, -500]} />
      </Block> */}

      </Suspense>
    </Canvas>
  )
}
const WaveGroup = () => {
  const r = 30
  const y = 10
  return(
  <>
  <ambientLight color={conf.ambientColor} intensity={1}/>
  <pointLight color={conf.light1Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[0, y, r]}/>
  <pointLight color={conf.light2Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[0, -y, -r]}/>
  <pointLight color={conf.light3Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[r, y, 0]}/>
  <pointLight color={conf.light4Color} intensity={ conf.lightIntensity} distance={lightDistance} position={[-r, y, 0]}/>
  <Wave />
  </>
  )
}

export {WaveGroup}

export default Background
