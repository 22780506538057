import React, { useEffect, useRef } from 'react'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"

import { useLoader, useFrame} from 'react-three-fiber'
import { IncrementStencilOp } from 'three'


export default function Model({ url }) {

  const gltf = useLoader(GLTFLoader, url)
  // const [mixer] = useState(() => new THREE.AnimationMixer(gltf.scene))
  const model = useRef()

  useFrame(() => {
    model.current.rotation.y += .01
  })

  // useEffect(() => void mixer.clipAction(gltf.animations[0]).play())
  //useFrame((state, delta) => { mixer.update(delta)})

  return gltf.scene ? <primitive ref={model} object={gltf.scene} position={[50,60,0]} scale={[100,100,100]} /> : null
}
