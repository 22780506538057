import { ShaderMaterial, Color, Vector3 } from "three"
import { extend } from "react-three-fiber"

class GradientMaterial extends ShaderMaterial {
  constructor() {
    super({
      vertexShader: `
        uniform vec3 bboxMin;
        uniform vec3 bboxMax;
    
        varying vec2 vUv;

        void main() {
          vUv.y = (position.y - bboxMin.y) / (bboxMax.y - bboxMin.y);
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
        }
      `,
      fragmentShader: `
      uniform vec3 color1;
      uniform vec3 color2;
      uniform vec3 color3;
      uniform vec3 color4;
      varying vec2 vUv;
      
      void main() {
        float h = 0.5;
        float h1 = 0.8;
        float y =  vUv.y;
        //gl_FragColor = vec4( mix(mix(mix(color1, color2, vUv.y/(h*h1)) , mix(color2, color3, vUv.y) , step((h*h1), vUv.y)), color4, step(.8, vUv.y)) ,1.0);

        gl_FragColor = vec4( mix(mix(color1, color2, vUv.y/h) , mix(color2, color3, (vUv.y - h)/(1.0 - h)) , step(.5, vUv.y)),1.0);
        //gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
      }
    `,
      uniforms: {
        texture: { value: null },
        hasTexture: { value: 0 },
        scale: { value: 0 },
        shift: { value: 0 },
        opacity: { value: 1 },
        color2: {value: new Color(0x9400FF)},
        color1: {value: new Color(0x000000)},
        color3: {value: new Color(0xFFA94C)},
        color4: {value: new Color(0xFFFFFF)},
        bboxMin: {value: new Vector3(-1,-1,0)},
        bboxMax: {value: new Vector3(1,1,0)}
      }
    })
  }

  set bboxMax(value){
    this.uniforms.bboxMax.value = value
  }

  get bboxMax(){
    return this.uniforms.bboxMax.value
  }

  set bboxMin(value){
    this.uniforms.bboxMin.value = value
  }

  get bboxMin(){
    return this.uniforms.bboxMin.value
  }

  set scale(value) {
    this.uniforms.scale.value = value
  }

  get scale() {
    return this.uniforms.scale.value
  }

  set shift(value) {
    this.uniforms.shift.value = value
  }

  get shift() {
    return this.uniforms.shift.value
  }

  set map(value) {
    this.uniforms.hasTexture.value = !!value
    this.uniforms.texture.value = value
  }

  get map() {
    return this.uniforms.texture.value
  }

  get color1() {
    return this.uniforms.color1.value
  }

  get color2() {
    return this.uniforms.color2.value
  }


  get opacity() {
    return this.uniforms.opacity.value
  }

  set opacity(value) {
    if (this.uniforms) this.uniforms.opacity.value = value
  }
}

extend({ GradientMaterial })
